import React from "react";

export default function NonVegIcon({style}) {
  return (
    <svg width="15" height="15" viewBox="0 0 230 230" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="6" width="218" height="218" stroke="#8E3C3C" stroke-width="12"/>
    <path d="M115 42L190.344 172.5H39.6558L115 42Z" fill="#8E3C3C"/>
    </svg>
  );
}

