import { Button } from "@material-ui/core";
import { Directions, Phone, WhatsApp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectrestaurant } from "../restaurant/restaurantSlice";
import {useHistory, useParams} from "react-router-dom"
export default function Contact() {
  const restaurant = useSelector(selectrestaurant);
  const history = useHistory()
  const params = useParams()
  console.log(params);
    useEffect(() => {
      if(Boolean(!restaurant.restaurantId)){
        history.push(`/restaurant/${params.restaurantId}`)
      }
    
      return () => {
        
      }
    }, [ params.restaurantId, restaurant])
    
  if (Boolean(!restaurant.restaurantId))
    return (
      <div>
        {" "}
        <br />
        <br />
        &ensp;&ensp;Loading
      </div>
    );
    else if (restaurant)
  return (
    <div style={{ margin: "16px", textAlign: "left" }}>
      <div>
        <div style={{ fontWeight: 700 }}>Contact Restaurant</div>
      </div>
      <br />
      <div>
        {restaurant?.restaurant?.mobile && <div className="flex" style={{ justifyContent: "space-between" }}>
          Phone &ensp;
          <a href={`tel:${restaurant?.restaurant?.mobile}`}>
            <Button color="primary" variant="outlined">
              <Phone />
            </Button>
          </a>
        </div>}
        <br />
        <div className="flex" style={{ justifyContent: "space-between" }}>
          Whatsapp &ensp;
          <a
            href={`https://api.whatsapp.com/send?phone=91${restaurant?.restaurant.whatsapp}`}
          >
            <Button color="primary" variant="outlined">
              <WhatsApp />
            </Button>
          </a>
        </div>
        <br />
        <div className="flex" style={{ justifyContent: "space-between" }}>
          Location &ensp;
          <a
            href={`http://www.google.com/maps/place/${restaurant?.restaurant.latitude},${restaurant?.restaurant.longitude}`}
          >
            <Button color="primary" variant="outlined">
              <Directions />
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
