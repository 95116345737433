import React from "react";

export default function FilterIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.43192 4.57141C7.73503 4.57141 8.02572 4.69182 8.24004 4.90615C8.45437 5.12047 8.57478 5.41116 8.57478 5.71427V7.99998C8.57478 8.30309 8.45437 8.59378 8.24004 8.8081C8.02572 9.02243 7.73503 9.14284 7.43192 9.14284C7.12882 9.14284 6.83813 9.02243 6.6238 8.8081C6.40947 8.59378 6.28906 8.30309 6.28906 7.99998V5.71427C6.28906 5.41116 6.40947 5.12047 6.6238 4.90615C6.83813 4.69182 7.12882 4.57141 7.43192 4.57141V4.57141Z"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.1456 6.85718H8.57422"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.28795 6.85718H2.85938"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.43192 16C7.73503 16 8.02572 16.1204 8.24004 16.3347C8.45437 16.5491 8.57478 16.8398 8.57478 17.1429V19.4286C8.57478 19.7317 8.45437 20.0224 8.24004 20.2367C8.02572 20.451 7.73503 20.5714 7.43192 20.5714C7.12882 20.5714 6.83813 20.451 6.6238 20.2367C6.40947 20.0224 6.28906 19.7317 6.28906 19.4286V17.1429C6.28906 16.8398 6.40947 16.5491 6.6238 16.3347C6.83813 16.1204 7.12882 16 7.43192 16Z"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.1456 18.2856H8.57422"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.28795 18.2856H2.85938"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5764 10.2858C16.8796 10.2858 17.1702 10.4062 17.3846 10.6205C17.5989 10.8348 17.7193 11.1255 17.7193 11.4286V13.7143C17.7193 14.0174 17.5989 14.3081 17.3846 14.5225C17.1702 14.7368 16.8796 14.8572 16.5764 14.8572C16.2733 14.8572 15.9827 14.7368 15.7683 14.5225C15.554 14.3081 15.4336 14.0174 15.4336 13.7143V11.4286C15.4336 11.1255 15.554 10.8348 15.7683 10.6205C15.9827 10.4062 16.2733 10.2858 16.5764 10.2858V10.2858Z"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4308 12.5714H2.85938"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.1473 12.5714H17.7188"
        stroke="#3C3C43"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
